import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { RootState } from '../../store/store';
import { LayoutBasic } from '../../pages/layout/LayoutBasic';

export const PrivateRoutes = () => {
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

    const checkIsLoggedIn = () => {
        return isAuthenticated;
    };

    return checkIsLoggedIn() ? (
        <LayoutBasic>
            <Outlet />
        </LayoutBasic>
    ) : (
        <Navigate to="/" />
    );
};
