import { Spin } from 'antd';
import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PageRouteProps } from './types';
import { LoadingOutlined } from '@ant-design/icons';
import { PrivateRoutes } from './PrivateRoutes';
import { LoginPage } from '../../pages/login/LoginPage';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface Props {
    routes: PageRouteProps[];
}

export function Navigator({ routes }: Props) {
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

    function renderRoutes(routes?: PageRouteProps[]) {
        if (!routes?.length) return;

        return routes.map((route) => {
            return (
                <Route key={String(route.path)} path={route.path} {...route}>
                    {renderRoutes(route.routes)}
                </Route>
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            );
        }) as any;
    }

    return (
        <Suspense
            fallback={
                <div className="spinner-div">
                    <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} />
                </div>
            }
        >
            <Routes>
                <Route path="login" Component={LoginPage} />
                <Route element={<PrivateRoutes />}>{renderRoutes(routes)}</Route>
                <Route path="*" element={<Navigate replace to={isAuthenticated ? '/users' : '/login'} />} />
            </Routes>
        </Suspense>
    );
}
