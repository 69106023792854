import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isTokenValid } from '../../utils/token';

export interface AuthSlice {
    isAuthenticated: boolean;
}

const initialState: AuthSlice = {
    isAuthenticated: isTokenValid(),
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isAuthenticated: action.payload,
            };
        },
        setPreFilledEmail: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                preFilledEmail: action.payload,
            };
        },
    },
});

export const { setIsAuthenticated, setPreFilledEmail } = authSlice.actions;

export const authReducer = authSlice.reducer;
