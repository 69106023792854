import { lazy } from 'react';
import { PageRouteProps } from './components/navigator/types';

export const LoginPage = lazy(() => import('./pages/login/LoginPage').then((module) => ({ default: module.LoginPage })));
const UsersPage = lazy(() => import('./pages/users/UsersPage').then((module) => ({ default: module.UsersPage })));
const UsersEditPage = lazy(() => import('./pages/users/edit/UsersEditPage').then((module) => ({ default: module.UsersEditPage })));
const WithdrawRequestsPage = lazy(() =>
    import('./pages/withdraw-requests/WithdrawRequestsPage').then((module) => ({ default: module.WithdrawRequestsPage })),
);
const WithdrawRequestEditPage = lazy(() =>
    import('./pages/withdraw-requests/edit/WithdrawRequestEditPage').then((module) => ({ default: module.WithdrawRequestEditPage })),
);
const FeedbackPage = lazy(() => import('./pages/feedback/FeedbackPage').then((module) => ({ default: module.FeedbackPage })));

export function getRoutes(): PageRouteProps[] {
    const routes: PageRouteProps[] = [
        {
            path: 'users',
            routes: [
                {
                    path: '',
                    Component: UsersPage,
                },
                {
                    path: 'edit/:userId',
                    Component: UsersEditPage,
                },
            ],
        },
        {
            path: 'withdraw-requests',
            routes: [
                {
                    path: '',
                    Component: WithdrawRequestsPage,
                },
                {
                    path: 'edit/:withdrawRequestId',
                    Component: WithdrawRequestEditPage,
                },
            ],
        },
        {
            path: 'feedback',
            routes: [
                {
                    path: '',
                    Component: FeedbackPage,
                },
            ],
        },
    ];
    return routes;
}
