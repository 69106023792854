import React, { useState } from 'react';
import Wrapper from './styles';
import { Button, Form, Input, Space, Spin, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { authWithEmailPassword } from '../../api/auth';
import { store } from '../../store/store';
import { setIsAuthenticated } from '../../store/slices/authSlice';
import { useNavigate } from 'react-router-dom';

export function LoginPage() {
    const [form] = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    async function login() {
        setIsLoading(true);
        const values = form.getFieldsValue();
        const result = await authWithEmailPassword(values);
        store.dispatch(setIsAuthenticated(result.success));
        if (result.message) {
            notification.error(result.message);
        }
        if (result.success) {
            navigate('/users');
        }
        setIsLoading(false);
    }

    async function onPressEnter(key: string) {
        if (key === 'Enter') {
            await login();
        }
    }

    return (
        <Wrapper>
            <Space direction="vertical">
                <Form form={form} onKeyUp={(element) => onPressEnter(element.key)} layout="vertical">
                    <Form.Item name="email" label="Email">
                        <Input placeholder="email" />
                    </Form.Item>
                    <Form.Item name="password" label="Password">
                        <Input placeholder="password" type="password" />
                    </Form.Item>
                    <Button onClick={login}>Login</Button>
                </Form>
                {isLoading && <Spin />}
            </Space>
        </Wrapper>
    );
}
