import axios from '../utils/axios';
import { getTokens, setToken } from '../utils/token';

export const authWithEmailPassword = async ({ email, password }: { email: string; password: string }) => {
    try {
        const result = await axios.post('/auth/login', { email, password });
        setToken(result.data);
        return { success: true };
    } catch (err: any) {
        const message = err?.response?.data?.description ?? '';
        return { success: false, message };
    }
};

export const refreshToken = async () => {
    try {
        const tokens = getTokens();
        const result = await axios.post('/auth/refresh', tokens);
        setToken(result.data);
    } catch (err) {
        console.log(err);
    }
};
