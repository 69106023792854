import React from 'react';
import { ConfigProvider, App as AntdApp } from 'antd';
import { getRoutes } from './routes';
import { Navigator } from './components/navigator/Navigator';

function App() {
    const routes = getRoutes();
    return (
        <ConfigProvider>
            <AntdApp>
                <Navigator routes={routes} />
            </AntdApp>
        </ConfigProvider>
    );
}

export default App;
